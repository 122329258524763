// Main Base

.app-main {
  z-index: 8;
  position: relative;
  display: flex;

  .app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
    width: 1px;
  }

  .spanClick {
    background: #7393ee5e;
    padding: 0px 6px;
    border-radius: 50%;
    color: black;
    font-weight: 600;
    cursor: pointer;
}

.spanClick:hover{
  background: #7393eeb5;
  color: white;
  transition: all 0.2s ease;
}

  .spanClickBlock {
    background: #7393ee3b;
    padding: 0px 6px;
    border-radius: 3px;
    color: black;
    font-weight: 600;
    cursor: pointer;
}

.spanClickBlock:hover{
  background: #7393eeb5;
  color: white;
  transition: all 0.2s ease;
}

.breadcrumbs {
    color: #545cd8;
    font-size: 12px;
}

@media screen and (max-width:991px) {
  .breadcrumbs {
    display: inline-block;
    padding-bottom: 10px;
}
}

.tableHeader th{
  color: #545cd8;
}

.customTable{
  border-top: 3px solid #0000001c !important;
}

.customTable tfoot {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  font-weight: bold;
  color: #495057;
}

.customTable tfoot tr {
  background: rgb(195, 202, 216);
}

.redColor{
  background-color: #f8d2d2;
}

.yellowColor{
  background-color: #fff4b8;
}

.greenColor{
  background-color: #e5ffd3;
}

.heightZero{
  height: 0;
  transition: all 0.2s ease;
}

.heightHundred{
  height: 100%;
  transition: all 0.2s ease;
}

.noDisplay{
  display: none;
}

  .app-main__inner {
    padding: $grid-gutter-width $grid-gutter-width 0;
    flex: 1;

    & > .RRT__container {
      & > .RRT__panel {
        margin-top: $grid-gutter-width;
      }

      & > .body-tabs-layout {
        .RRT__tab {
          margin-top: (-($layout-spacer-x / 3));
        }
      }
    }
  }
}

// Alternate Section Tabs

.body-tabs-shadow-btn {

  .app-main__inner {
    & > .RRT__container {
      & > .RRT__panel {
        margin-top: ($grid-gutter-width / 1.5);
      }

      .body-tabs-layout {
        border: 0;
        background: transparent;
        position: relative;
        z-index: 7;

        .RRT__showmore {
          margin-top: -13px;
        }

        .RRT__tab {
          margin-top: $nav-link-padding-y;
        }

        .RRT__tab--selected {
          color: $white;
        }

        & + .RRT__inkbar-wrapper {
          position: absolute;
          top: ($nav-link-padding-y * 2.2);
          z-index: 5;

          .RRT__inkbar {
            height: 35px;
            margin-top: 0;
            @include border-radius($border-radius);
            box-shadow:
                    0 16px 26px -10px rgba($primary, 0.56),
                    0 4px 25px 0px rgba(0, 0, 0, 0.12),
                    0 8px 10px -5px rgba($primary, 0.2);
          }
        }
      }
    }
  }


}

// Themes

@import "themes/main-white";
@import "themes/main-gray";
//@import "themes/main-inverted-blue";
//@import "themes/main-inverted-black";
