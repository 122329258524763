.pnf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  color: #333;

  .text-center {
    text-align: center;
    h1,
    h3 {
      margin: 10px;
      cursor: default;
    }

    h1 {
      font-size: 10rem;
      font-family: Roboto;
      font-weight: 700;
      color: #000;
    }

    h3 {
      color: #0069af;
      font-size: 17px;
    }

    p {
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .pnf-container .text-center h1 {
    font-size: 6rem;
  }
}
