.map-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 350px;
    overflow-x: auto;
    border: 1px solid #e9ecef;
}
.leaflet-container {
    height: 100%;
    max-height: 450px;
    min-height: 300px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    z-index: 0;
    // pointer-events: none;
}