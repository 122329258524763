.inner-donuts {
    margin-top: 30px;

    .card {
        border: 1px solid rgb(233, 236, 239);
        box-shadow: none;
        border-radius: 0px;

        .card-body {
            .btn {
                display: none;
            }
        }
    }

    // .collection circle:not(:first-of-type){
    //     stroke: #3ac47d ;
    // }

    // .segregation circle:not(:first-of-type){
    //     stroke: #30b1ff;
    // }

    // .no-waste circle:not(:first-of-type){
    //     stroke: #30b1ff;
    // }
}

// rgb(248, 210, 210)  rgb(229, 255, 211)