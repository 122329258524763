// Helpers imports
@import "helpers/opacity";
@import "helpers/grayscale";

// Resets

.br-tl {
  border-top-left-radius: $border-radius !important;
}

.br-tr {
  border-top-right-radius: $border-radius !important;
}

.br-bl {
  border-bottom-left-radius: $border-radius !important;
}

.br-br {
  border-bottom-right-radius: $border-radius !important;
}

.b-radius-0 {
  border-radius: 0 !important;
}

.rm-border {
  border-width: 0 !important;
}

.br-a {
  @include border-radius($border-radius);
}

// Center Elements

.margin-h-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-svg {
  margin: 0 auto;

  svg {
    margin: 0 auto;
  }
}

.apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-donut {
  display: flex;
  align-items: center;
  align-content: center;
}

.alert-dismissible {
  .close {
    top: 0;
    right: 5px;
    padding: 5px;
  }
}

// Gradient Icons

.icon-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
 //-text-fill-color: transparent;
}

// Font sizes

.font-size-xlg {
  font-size: 1.3rem !important;
}

.font-size-md {
  font-size: 0.9rem !important;
}

.font-size-lg {
  font-size: 1.1rem !important;
}

// No Shadow

.no-shadow {
  box-shadow: 0 0 0 transparent !important;
}

// Layout

.h-100 {
  height: 100vh !important;
}

.he-auto {
  height: auto !important;
}

.he-100 {
  height: 100%;
}

.h-sm {
  height: 150px;
}

.text-nowrap {
  white-space: nowrap;
}

// Font size

.fsize-1 {
  font-size: 0.95rem !important;
}

.fsize-2 {
  font-size: 1.3rem !important;
}

.fsize-3 {
  font-size: 1.6rem !important;
}

.fsize-4 {
  font-size: 2rem !important;
}

.z-index-6 {
  z-index: 6;
}

.line-height-1 {
  line-height: 1;
}

// Center element

.center-elem {
  display: flex;
  align-items: center;
  align-content: center;
}

.flex2 {
  flex: 2;
}

// Divider

.divider {
  margin-top: $spacer;
  margin-bottom: $spacer;
  height: 1px;
  overflow: hidden;
  background: $gray-200;
}

.list-group-item:hover {
  z-index: initial;
}

// No Results

.no-results {
  padding: ($spacer * 1.5);
  text-align: center;

  .results-title {
    color: $gray-700;
    font-size: $h5-font-size;
  }

  .results-subtitle {
    color: $gray-500;
    font-size: $font-size-lg;
  }
}

// Background Color Animation

.bg-animation {
  animation: bg-pan-left 8s both;
}

@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// Width 100%

.w-100 {
  width: 100%;
}

.mb--2 {
  margin-bottom: -1.5rem;
}

// Margins

.mbg-3 {
  margin-bottom: $grid-gutter-width;
}

// Loader

.loader-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  text-align: center;
  line-height: 1.6rem;

  .loader-container-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 $layout-spacer-x;
  }

  small {
    margin: 10px 0 0;
    display: block;
    color: $gray-600;
    font-weight: normal;
  }

  h3 {
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 991px) {
  .empty-label {
    display: none;
  }
}

// metis menu

a[href="#/summary-sheets"] {
  color: #3ac47d !important;
}

.lh-38 {
  line-height: 38px;
}

.css-15k3avv {
  z-index: 12 !important;
}

.mh-450 {
  // height: 450px;
  max-height: 437px;
  overflow: auto;
}

.scroller .rt-thead {
  overflow-y: scroll;
}

.ReactTable .rt-thead .rt-th {
  border-bottom: 0 !important;
}

.ReactTable .rt-thead {
  border-bottom: 1px solid #e9ecef;
}

.greenBlock {
  background: #e5ffd3;
}

.yellowBlock {
  background: #fff4b8;
}

.orangeBlock {
  background: #ffcb8d;
}

.redBlock {
  background: #f8d2d2;
}

.greenBlockPie {
  background: #86d451;
}

.yellowBlockPie {
  background: #ecd65c;
}

.orangeBlockPie {
  background: #fab666;
}

.redBlockPie {
  background: #f1a2a2;
}

.legendContent p {
  padding: 0 5px;
  margin-bottom: 4px;
  border-bottom: 2px solid #ddd;
}

.legendContent p:last-child {
  margin-bottom: 0;
}

.markersLegend,
.legendContent {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: 10px;
}

.markersLegend p {
  margin-bottom: 5px;
}

.markersLegend p:last-child {
  margin-bottom: 0;
}

.modalImage {
  text-align: center;
}

.modalImage img {
  max-width: 100%;
}

.vertical-data-container {
  display: flex;
  flex-wrap: wrap;
  // background-color: DodgerBlue;
  justify-content: flex-start;
}

.vertical-data-container > div {
  background-color: white;
  width: 5%;
  min-width: 35px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  border: 1px solid #e9ecef;
}

.app-main .app-main__outer {
  max-width: 100%;
}

.header-btn {
  width:100%; 
  height:100%; 
  border:0px;
  background-color:rgb(212, 212, 212)
}

.header-btn:hover {
  background-color: rgb(199, 199, 199);
}

.header-heading {
  font-weight: 600; 
  font-size: 18px;
  color: rgb(70, 68, 68);
}

.tooltips {
  display: inline-block;
  text-indent:0em;
  position: relative;
  width: 100%;
}

.tooltips .tooltiptext {
  display: none;
  background-color: rgb(63, 63, 63);
  color: #fff;
  text-align: center;
  width: 300px;
  border-radius: 6px;
  padding: 5px 5px;
  left: 10px;
  border: 2px solid grey;
  line-height: normal;
  // text-decoration: none;
  position: absolute;
  z-index: 6;
}

// .tooltips .tooltiptext::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #555 transparent transparent transparent;
// }

.tooltips:hover .tooltiptext {
  display: block;
}


