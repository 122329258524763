.custom-table td .form-control {
  text-align: center;
}
.custom-table td .form-control:disabled {
  background: none;
  border: none;
  text-align: center;
}
.custom-table th,
.input-table th {
  color: #545cd8;
}
.custom-table .form-control {
  text-align: center;
}
.custom-table .form-control:disabled {
  background-color: transparent;
  border: 0px;
}
.clusterName {
  font-weight: 700;
}
.scrollBar-remove .ReactTable.scroller .rt-thead {
  overflow-y: auto !important;
}
.scrollBar-remove ::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.scrollable-table .rt-thead,
.operations-table .rt-thead {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
}

.scrollable-table .rt-thead,
.scrollable-table .rt-tbody,
.operations-table .rt-thead,
.operations-table .rt-tbody {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-table .rt-tbody::-webkit-scrollbar,
.operations-table .rt-tbody::-webkit-scrollbar {
  display: none;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.badge-hover:hover {
  background: #007bff;
}

// for collcetion and segregation modal table
.ReactTable.table-height .rt-tbody {
  max-height: 200px;
}

.colored-footer .rt-tfoot {
  background: rgb(195, 202, 216);
}
